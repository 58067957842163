import { SubmitStatus } from '../models/enhancement'

export const VIEW_PLANNER_365 = 'ViewPlanner365'
export const VIEW_HOLIDAY_SNAPSHOT = 'ViewHolidaySnapshot'
export const VIEW_COMINGUP = 'ViewComingUp'
export const VIEW_WHO_AWAY_MANAGER = 'ViewWhoAwayManager'
export const VIEW_WHOAWAY_TEAM = 'ViewWhoAwayTeam'
export const VIEW_MY_ACTIONS = 'ViewMyActions'
export const CREATE_REQUEST = 'CreateRequest'
export const VIEW_MY_AVAILABILITY = 'ViewMyAvailability'
export const VIEW_MY_REQUESTS = 'ViewMyRequests'
export const VIEW_MY_EXTRA = 'ViewMyExtra'
export const EDIT_MY_EXTRA = 'EditMyExtra'
export const VIEW_WALLCHART_TEAM = 'ViewWallChartTeam'
export const VIEW_WALLCHART_DEPARTMENTS = 'ViewWallChartDepartments'
export const VIEW_MANAGER_DIRECTREPORT = 'ViewManagerDirectReport'
export const VIEW_MANAGER = 'ViewManager'
export const MANAGE_REQUESTS = 'ManageRequests'
export const VIEW_ATTENDANCE = 'ViewAttendance'
export const EDIT_ATTENDANCE = 'EditAttendance'
export const DISCOUNT_ABSENCE = 'DiscountAbsence'
export const DELETE_ATTENDANCE = 'DeleteAttendance'
export const CREATE_ATTENDANCE = 'CreateAttendance'
export const VIEW_SETTINGS = 'ViewSettings'
export const EDIT_SETTING = 'EditSetting'
export const VIEW_THRESHOLDS = 'ViewThresholds'
export const EDIT_THRESHOLDS = 'EditThresholds'
export const VIEW_RESTRICTIONS = 'ViewRestrictions'
export const EDIT_RESTRICTIONS = 'EditRestrictions'
export const VIEW_DEPARTMENT = 'ViewDepartment'
export const BOOKING_USER = 'BookingUser'
export const BOOKING_MANAGER = 'BookingManager'
export const BOOKING_EDITOR = 'BookingEditor'
export const BOOKING_REPORTS = 'BookingReports'
export const EDIT_LOCATIONS = 'EditLocations'
export const REPORTING = 'Reporting'
export const SEARCH_ATTENDANCE = 'SearchAttendance'
export const ATTENDANCE_HISTORY = 'AttendanceHistory'
export const HISTORY_HOLIDAY = 'HistoryHoliday'
export const REPORTS_HOLIDAY = 'ReportsHoliday'
export const ENHANCEMENT_SUBMITTER = 'EnhancementSubmitter'

export const dashboardPermissions = [
  VIEW_PLANNER_365,
  VIEW_HOLIDAY_SNAPSHOT,
  VIEW_COMINGUP,
  VIEW_WHOAWAY_TEAM,
  VIEW_WHO_AWAY_MANAGER,
  CREATE_REQUEST,
]

export const myAvailabilityPermissions = [VIEW_MY_AVAILABILITY]

export const myRequestsPermissions = [VIEW_MY_REQUESTS]

export const myExtrasPermissions = [VIEW_MY_EXTRA, EDIT_MY_EXTRA]

export const wallChartPermisssions = [
  VIEW_WALLCHART_TEAM,
  VIEW_WALLCHART_DEPARTMENTS,
  VIEW_MANAGER_DIRECTREPORT,
]

export const managerPermisssions = [VIEW_MANAGER, MANAGE_REQUESTS, DISCOUNT_ABSENCE]

export const enhancementPermissions = [ENHANCEMENT_SUBMITTER]

export const bookingPermisssions = [BOOKING_USER, BOOKING_MANAGER, BOOKING_EDITOR, BOOKING_REPORTS]

export const settingsPermissions = [
  VIEW_SETTINGS,
  EDIT_SETTING,
  VIEW_THRESHOLDS,
  EDIT_THRESHOLDS,
  VIEW_RESTRICTIONS,
  EDIT_RESTRICTIONS,
  VIEW_DEPARTMENT,
]

export const REQUIRED_MESSAGE = 'This field is required'
export const CLASH_OVERRIDE_MESSAGE =
  'By Submitting, you will override the clashes you have been alerted to with this new occurrrence. Or you can choose to remove these clashes'

export const REQUEST_CLASH_CANNOT_SUBMIT_MESSAGE =
  'Please remove the clashes highlighted in order to proceed.'

export const DAY_MS = 86400000
export const ON_CALL_HOURS = 2
export type EntitlementPeriodType = 'FinancialYear' | 'CalendarYear'
export type SyndicateTypeType = 'Rank' | 'Hierarchical'

export const DEFAULT_ALLOWED_YEARS_IN_PAST = -2
export const DEFAULT_ALLOWED_YEARS_IN_FUTURE = 1
export const SYNDICATE_HIERARCHICAL_YEARS_IN_FUTURE = 4
export const SYNDICATE_RANKED_YEARS_IN_FUTURE = 1
export const ADJUSTMENT_ALLOWED_YEARS_IN_PAST = 0
export const ADJUSTMENT_ALLOWED_YEARS_IN_FUTURE = 1
export const HSS_YEARS_IN_THE_PAST = -2
export const HSS_YEARS_IN_THE_FUTURE = 1
export const HTL_YEARS_IN_THE_PAST = -2
export const HTL_YEARS_IN_THE_FUTURE = 4

export const WEEKDAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]
export const WEEKDAYS_SHORT = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
export const MONTHS_SHORT = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jly',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

export const COMING_UP_REQUEST_TYPE_IDS = [1, 2, 5, 6, 7]
export const COMING_UP_MANUAL_TYPE_IDS = [9, 3, 4, 5, 2]

// eslint-disable-next-line no-shadow
export enum AbsenceStatus {
  AWAITING_ED = 'Awaiting ED',
  AWAITING_SCA = 'Awaiting SCA',
}

export interface Labels {
  [index: string]: string
}

export const absenceFormLabels: Labels = {
  absenceDetail: 'Absence Detail',
  continuationOfAbsence: 'Continuation of absence starting:',
  employee: 'Employee',
  shift: 'Shift',
  expectedReturn: 'Expected Return',
  partDayAbsence: 'Part Day Absence',
  absentFrom: 'Absent from',
  hasHadLunch: 'Has had lunch',
  toWorkBack: 'To work back',
  absenceType: 'Absence Type',
  absenceReason: 'Reason for absence',
  contactMadeBy: 'Contact made by',
  contactedDate: 'Contacted date',
  contactComments: 'Contact Comments',
  comments: 'Comments',
  sendFdn: 'Send First Day Notification',
  occurrenceSummary: 'Occurrence Summary',
  helperRequired: 'Required',
  clashMessage: 'There is a clash on this day with a current',
  selectEmployee: 'Please select an Employee',
}

export const enhancementSubmitStatusDisplayNames: { [key: string]: string } = {
  [SubmitStatus.PENDING]: 'Pending',
  [SubmitStatus.DECLINED]: 'Declined',
  [SubmitStatus.CANCELLED]: 'Cancelled',
  [SubmitStatus.APPROVED]: 'Approved',
  [SubmitStatus.SUBMITTED]: 'Submitted',
  [SubmitStatus.AMENDED]: 'Amended',
}

export const submitCancelButtons: Labels = {
  cancel: 'Cancel',
  submit: 'Submit',
}
export const operationTypes = {
  APPROVE: 'approve',
  DECLINE: 'decline',
} as const
export type OperationType = (typeof operationTypes)[keyof typeof operationTypes]

export const requestOperations = {
  approve: 'approve',
  decline: 'decline',
}

export const absenceFormModalClash: Labels = {
  title: 'Occurrence Clash!',
  message: 'Are you sure you want to override the clashes with this absence?',
  button: 'Yes',
}

// eslint-disable-next-line no-shadow
export enum FlattenedRequestType {
  Unspecified = 'Unspecified',
  Holiday = 'Holiday',
  DayOff = 'DayOff',
  Shift = 'Shift',
  Manual = 'Manual',
  Lieu = 'Lieu',
  WorkFromHome = 'WorkFromHome',
  Birthday = 'Birthday',
  Buy = 'Buy',
  Sell = 'Sell',
  Absence = 'Absence',
  Late = 'Late',
  Adjustment = 'Adjustment',
  Course = 'Course',
  LieuDay = 'LieuDay',
  MaternityLeave = 'MaternityLeave',
  PaternityLeave = 'PaternityLeave',
  Other = 'Other',
  NightShiftCover = 'NightShiftCover',
  TwilightShiftCover = 'TwilightShiftCover',
  ForcedHoliday = 'ForceHoliday',
  Overtime = 'Overtime',
  Night = 'Night',
  OnCall = 'OnCall',
  CallOut = 'CallOut',
}

// eslint-disable-next-line no-shadow
export enum BookingSliderPosition {
  BOOKINGS = 0,
  FLOOR_PLAN = 1,
}

export const typesOfRequest = [
  'Work From Home',
  'Holiday',
  'Holiday (M)',
  'Lieu',
  'Day Off',
  'Shift',
  'Adjustment',
  'Course',
  'Maternity',
  'Paternity',
  'Other',
  'Night Shift Cover',
  'Twilight Shift Cover',
  'Adjustment (M)',
  'Course (M)',
  'Lieu (M)',
  'Maternity (M)',
  'Paternity (M)',
  'Other (M)',
  'Night Shift Cover (M)',
  'Twilight Shift Cover (M)',
  'Overtime (M)',
  'Night (M)',
  'Call Out (M)',
]

export const enhancementsContent = {
  enhancements: 'Enhancements',
  noData: 'No data available',
  total: 'Total',
  submitToPayrollActive: (pendingCount: number) => `Submit ${pendingCount} to Payroll`,
  submitToPayrollPeriodClosed: 'Period Closed',
  submitToPayrollNothingToSubmit: 'Nothing to Submit',
  submitToPayrollSuccessMessage: 'Enhancements submitted to payroll successfully',
  submitToPayrollPreSubmitMessage: (submitByTime: string, submitByDateFormatted: string) =>
      `Once you confirm submission, you can still submit any remaining enhancements 
      within this pay period by ${submitByTime} on ${submitByDateFormatted}`,
}

export const enhancementTypes = {
  night: 'Night',
  onCall: 'On Call',
  overtime: 'Overtime',
  callOut: 'Call Out',
}

export const globalContent = {
  details: 'Details',
  requestSummary: 'Request Summary',
  duration: 'Duration',
  paidHours: 'Paid Hours',
  from: 'From',
  to: 'To',
  required: 'Required',
  submitBy: 'Submit by:',
  dateFrom: 'Date from',
  dateTo: 'Date to',
  dateSubmitted: 'Date submitted',
  totalHours: 'Total Hours',
  requesterComments: 'Requester comments',
  callouts: 'Call Outs'
}

export const enhancementContent = {
  zeroHoursValidation: 'Total hours must be greater than zero',
}

export const callOutFromContent: Labels = {
  calledOutBy: 'Called by',
  issueType: 'Issue Type',
  escalationType: 'Escalation Type',
  escalated: 'Escalated?',
  requireEscalation: 'Require escalation',
  escalatedTo: 'Escalated to',
  escalatedToTooltip: 'Did the call out require you to raise the issue to another person/team?',
  reason: 'Reason',
  jiraReference: 'Jira/Incident Reference',
  conclusion: 'Conclusion',
  productAffected: 'Product Affected',
  product: 'Product',
  codeChangeReleased: 'Code change released?',
  logInRequired: 'Log in required?',
  initialTotalHours: 'Logged Time',
  paidHours:'Hours Paid',
  loginRequiredTooltip:
    'After receiving the call, did you need to log in to your computer to complete any actions?',
  selectTeam: 'Select Team',
  team: 'Team',
  transferredToTeam: 'Transferred to another team?',
  select: 'Select',
  selectIssue: 'Select Issue',
  calledOut: 'Called out by',
  timeAndDate: 'Time & Date',
  description: 'Description',
  issueDescription: 'Issue Description',
  adjutedHoursTooltip:
    'If your total call-out duration within any 12-hour period is less than 2 hours, you will receive payment ' +
      'for 2 hours. If your call-out duration exceeds 2 hours, you will be paid for the actual time ' +
      'worked within that 12-hour rolling period, starting from your first call-out.',
  negetiveDuration: 'Duration must be a minimum of 2 hours',
  endTimeBeforeStartTime: 'End time cannot be before start time'
}
