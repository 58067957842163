import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { format } from "date-fns"
import { getComponentStyles, contentStyles } from './PayPeriodBannerStyles'
import { StatusChip } from '../../../shared/UI/StatusChip/StatusChip'
import { PayPeriodContent } from '../../../shared/UI/PayPeriodContent/PayPeriodContent'
import { SubmissionStatus } from "../../../shared/UI/SubmissionStatus/SubmissionStatus"

export const componentTestid = 'pay-period-banner'
export interface PayPeriodBannerProps {
  status: 'closed' | 'current' | 'next'
  statusDate: Date
  dateRange: {
    start: string
    end: string
  }
  currentPayPeriod: number
  disableNext: boolean
  disablePrevious: boolean
  onNext: () => void
  onPrevious: () => void
  submittedOn?: Date
  submitBy?: Date
}

export function PayPeriodBanner({
  status,
  statusDate,
  dateRange,
  currentPayPeriod,
  disableNext,
  disablePrevious,
  onNext,
  onPrevious,
  submittedOn,  
  submitBy,
}: PayPeriodBannerProps) {
  const { bannerStyles, disabledChevronStyles, enabledChevronStyles, textColor } =
    getComponentStyles(status)
  
  const getStatusLabel = () => {
    switch (status) {
      case 'closed':
        return 'Closed'
      case 'current':
        return 'Current'
      case 'next':
        return 'Next'
      default:
        return ''
    }
  }

  const getSubmissionLabel = () => {
    const labels: Record<string, string> = {
      closed: 'Submitted: ',
      current: submittedOn ? 'Last Submitted: ' : 'Submit by: ',
      next: 'Submit: ',
    }
    return labels[status] || ''
  }

  const getSubmissionDate = () => {
    if (status === 'current') {
      return submittedOn || submitBy || 'Not Available'
    }
    if (status === 'closed') {
      return submittedOn || 'Not Available'
    }
    return submitBy || statusDate || 'Not Available'
  }
  
  const getInfoText = () => {
      if (!submitBy) {
        return 'No submission deadline available'
      }
    const time = format(new Date(submitBy), 'HH:mm')
    const date = format(new Date(submitBy), 'do MMMM')

    switch (status) {
      case 'closed':
        return 'Unsubmitted items will roll over to the next period'
      case 'current':
        return `Cut off for additional submissions is the ${date}, ${time}`
      default:
        return ''
    }
  }


  return (
    <Box sx={bannerStyles} data-testid={componentTestid}>
      <IconButton
        data-testid={`${componentTestid}-previous`}
        size="small"
        disabled={disablePrevious}
        onClick={() => onPrevious()}
      >
        <ChevronLeftIcon sx={disablePrevious ? disabledChevronStyles : enabledChevronStyles} />
      </IconButton>
      <Box sx={{ ...contentStyles, display: 'flex', flexDirection: { xs: 'column', sm: 'column', md: 'row' }, alignItems: 'center', justifyContent: 'center' }}>
        <Box sx={{ marginBottom: { xs: '8px', sm: '8px', md: '0' }, marginRight: { xs: '0', md: '24px' } }}>
          <StatusChip type={status} label={getStatusLabel()} />
        </Box>
        <Typography
          sx={{ color: textColor, fontSize: { sm: '0.75rem' } }}
          data-testid={`${componentTestid}-range`}
        >
          <PayPeriodContent dateRange={dateRange} payPeriod={currentPayPeriod} />
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', margin: 'auto' }}>
          <Typography
            sx={{ color: textColor, fontSize: { sm: '0.75rem' } }}
          >
            <SubmissionStatus 
              submissionLabel={getSubmissionLabel()}
              statusDate={getSubmissionDate()}
              status={status}
            />
          </Typography>
        </Box>
        {getInfoText() ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: { xs: '8px', sm: '0' } }}>
            <InfoOutlinedIcon sx={{ color: textColor }} />
            <Typography sx={{ marginLeft: 0.5, color: textColor, fontSize: { xs: '0.625rem', sm: '0.75rem' } }}>
              {getInfoText()}
            </Typography>
          </Box>
          ) : (
            <Box sx={{ marginLeft: 'auto' }} />
          )}
      </Box>
      <IconButton
        data-testid={`${componentTestid}-next`}
        size="small"
        disabled={disableNext}
        onClick={() => onNext()}
      >
        <ChevronRightIcon sx={disableNext ? disabledChevronStyles : enabledChevronStyles} />
      </IconButton>
    </Box>
  )
}

